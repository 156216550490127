import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="next">
      <path
        id="next_2"
        d="M6.99812 21.015C7.48812 21.505 8.27812 21.505 8.76812 21.015L17.0781 12.705C17.4681 12.315 17.4681 11.685 17.0781 11.295L8.76812 2.985C8.27812 2.495 7.48812 2.495 6.99812 2.985C6.50812 3.475 6.50812 4.265 6.99812 4.755L14.2381 12.005L6.98812 19.255C6.50812 19.735 6.50812 20.535 6.99812 21.015Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
