import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  display: inline-block;
  background-color: #70c3cc;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 2px;

  &:hover {
    color: #fff;
    background-color: #8ec9b2;
  }
`

export default ({ children, to }) => <StyledLink to={to}>{children}</StyledLink>
