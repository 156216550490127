import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import ArticleMarker from "./ArticleMarker"
import FormattedDateRange from "./FormattedDateRange"

const Articles = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: left;
  align-items: stretch;
`

const Article = styled.div`
  width: 100%;
  margin: 2rem 0;

  @media (min-width: 600px) {
    padding: 0 1rem;

    &.standard-article {
      width: 50%;
    }
  }

  @media (min-width: 900px) {
    &.standard-article {
      width: 33.33%;
    }
  }
`

const ArticleLink = styled(Link)`
  text-decoration: none;
  &:hover {
    color: inherit;
  }

  @media (min-width: 600px) {
    .full-width-article & {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
    }
  }
`

const ArticleImgContainer = styled.div`
  margin-bottom: 0.5rem;
  background-color: #f0f0f0;
  height: 200px;
  overflow: hidden;

  @media (min-width: 600px) {
    .full-width-article & {
      width: calc(50%);
      margin: 0 1rem 0 0;
      height: 300px;
    }

    .first-article & {
      height: 300px;
    }
  }

  @media (min-width: 900px) {
    .first-article & {
      height: 450px;
    }
  }
`

const ArticleTextContainer = styled.div`
  @media (min-width: 600px) {
    .full-width-article & {
      width: calc(50% - 1rem);
      padding-left: 1rem;
    }
  }
`

const ArticleDate = styled.p`
  font-size: 0.75rem;
  margin: 0;
`

const ArticleTitle = styled.h3`
  margin: 0 0 0.5rem;

  @media (min-width: 600px) {
    .first-article & {
      font-size: 2rem;
    }
  }
  @media (min-width: 900px) {
    .full-width-article & {
      font-size: 2rem;
    }

    .first-article & {
      font-size: 3rem;
    }
  }
`

const ArticleExcerpt = styled.p`
  margin-bottom: 0;
`

export default ({ articles, firstIsFullWidth }) => (
  <Articles>
    {articles.map((article, index) => (
      <Article
        key={article.id}
        className={
          index === 0 && firstIsFullWidth
            ? "first-article"
            : index % 7 === 0
            ? "full-width-article"
            : "standard-article"
        }
      >
        <ArticleLink to={article.fields.slug}>
          <ArticleImgContainer>
            {article.frontmatter.mainPicture && (
              <Img
                fluid={article.frontmatter.mainPicture.childImageSharp.fluid}
                key={article.frontmatter.mainPicture.base}
                alt={article.frontmatter.mainPicture.base.split(".")[0]}
                style={{
                  height:
                    index === 0 && firstIsFullWidth
                      ? "450px"
                      : index % 7 === 0
                      ? "300px"
                      : "200px",
                }}
              />
            )}
          </ArticleImgContainer>
          <ArticleTextContainer>
            <ArticleDate>
              <FormattedDateRange
                from={article.frontmatter.date}
                to={article.frontmatter.dateEnd}
                useDayOfWeek={false}
              />
            </ArticleDate>
            <ArticleTitle>
              {article.frontmatter.title}
              <ArticleMarker
                isPublished={article.fields.isPublished}
                isPublic={article.fields.isPublic}
              />
            </ArticleTitle>
            <ArticleExcerpt>{article.excerpt}</ArticleExcerpt>
          </ArticleTextContainer>
        </ArticleLink>
      </Article>
    ))}
  </Articles>
)
