import React from "react"
import styled from "styled-components"

const Draft = styled.span`
  display: inline-block;
  padding: 1px 5px;
  text-transform: uppercase;
  background-color: #f00;
  font-size: 0.75rem;
  color: #fff;
  margin-left: 0.5rem;
  border-radius: 2px;
`

const Private = styled(Draft)`
  background-color: #90f;
`

const showDraft = process.env.SHOW_DRAFTS === "1"

export default ({ isPublished, isPublic }) => (
  <>
    {!isPublished && showDraft && <Draft>Draft</Draft>}
    {!isPublic && showDraft && <Private>Famille</Private>}
  </>
)
