import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import IconPrev from "./IconPrev"
import IconNext from "./IconNext"

const Container = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 2rem 0;
`

const PrevContainer = styled.div`
  margin-right: 32px;
  width: 200px;
`

const NextContainer = styled(PrevContainer)`
  margin-left: 32px;
  margin-right: 0;
  text-align: right;
`

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`

const StyledLinkPrev = styled(StyledLink)`
  & svg {
    margin-right: 8px;
  }
`

const StyledLinkNext = styled(StyledLink)`
  & svg {
    margin-left: 8px;
  }
`

const PageLink = styled.span`
  display: inline-block;
  margin: 0 10px;
`

const CurrentPage = styled(PageLink)`
  color: #999;
`

export default ({ current, total, linkBase, linkFirstPage }) => {
  const linkForFirstPage = linkFirstPage ? linkFirstPage : linkBase

  const getPages = () => {
    let content = []
    for (let i = 0; i < total; i++) {
      if (i === current - 1) {
        content.push(<CurrentPage>{i + 1}</CurrentPage>)
      } else {
        content.push(
          <PageLink>
            <Link
              to={i === 0 ? `${linkForFirstPage}` : `${linkBase}/${i + 1}`}
              key={i + 1}
            >
              {i + 1}
            </Link>
          </PageLink>
        )
      }
    }
    return content
  }

  if (total < 2) {
    return null
  }

  return (
    <Container>
      <PrevContainer>
        {current > 2 && (
          <StyledLinkPrev to={`${linkBase}/${current - 1}`}>
            <IconPrev />
            Précédent
          </StyledLinkPrev>
        )}
        {current === 2 && (
          <StyledLinkPrev to={`${linkForFirstPage}`}>
            <IconPrev />
            Précédent
          </StyledLinkPrev>
        )}
      </PrevContainer>

      {total > 2 && <div>{getPages()}</div>}

      <NextContainer>
        {current < total && (
          <StyledLinkNext to={`${linkBase}/${current + 1}`}>
            Suivant <IconNext />
          </StyledLinkNext>
        )}
      </NextContainer>
    </Container>
  )
}
