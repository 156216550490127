import React from "react"


export default ({from, to, hasDayOfWeek}) => {

  let startDate = from;
  let endDate = null;

  const monthIndex = hasDayOfWeek ? 2 : 1;
  const yearIndex = hasDayOfWeek ? 3 : 2;

  if (to !== null && from !== to) {

    const startDateParts = from.split(' ');
    const endDateParts = to.split(' ');

    let formattedStartDate = [startDateParts[0]];
    let formattedEndDate = [endDateParts[0]];

    if (hasDayOfWeek) {
      formattedStartDate.push(startDateParts[1]);
      formattedEndDate.push(endDateParts[1]);
    }

    if ((startDateParts[monthIndex] !== endDateParts[monthIndex]) || (startDateParts[monthIndex] === endDateParts[monthIndex] && startDateParts[yearIndex] !== endDateParts[yearIndex])) {
      formattedStartDate.push(startDateParts[monthIndex]);
      formattedEndDate.push(endDateParts[monthIndex]);

      if (startDateParts[yearIndex] !== endDateParts[yearIndex]) {
        formattedStartDate.push(startDateParts[yearIndex]);
        formattedEndDate.push(endDateParts[yearIndex]);
      } else {
        formattedEndDate.push(endDateParts[yearIndex]);
      }

    } else {
      formattedEndDate.push(endDateParts[monthIndex]);
      formattedEndDate.push(endDateParts[yearIndex]);
    }

    startDate = formattedStartDate.join(' ');
    endDate = formattedEndDate.join(' ');
  }

  return (
    <>
      {endDate ? `du ${startDate} au ${endDate}` : startDate}
    </>
  )
}
  

