import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="prev">
      <path
        id="prev_2"
        d="M17.0019 2.985C16.5119 2.495 15.7219 2.495 15.2319 2.985L6.92186 11.295C6.53186 11.685 6.53186 12.315 6.92186 12.705L15.2319 21.015C15.7219 21.505 16.5119 21.505 17.0019 21.015C17.4919 20.525 17.4919 19.735 17.0019 19.245L9.76186 11.995L17.0119 4.745C17.4919 4.265 17.4919 3.465 17.0019 2.985Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
